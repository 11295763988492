@font-face {
  font-family: 'Barlow Condensed Extra Bold';
  src: url('../../assets/fonts/barlow-condensed/BarlowCondensed-ExtraBold.eot');
  src: url('../../assets/fonts/barlow-condensed/BarlowCondensed-ExtraBold.eot?#iefix')
      format('embedded-opentype'),
    url('../../assets/fonts/barlow-condensed/BarlowCondensed-ExtraBold.ttf')
      format('truetype'),
    url('../../assets/fonts/barlow-condensed/BarlowCondensed-ExtraBold.woff')
      format('woff'),
    url('../../assets/fonts/barlow-condensed/BarlowCondensed-ExtraBold.woff2')
      format('woff2');
  font-weight: 800;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Barlow Condensed Bold';
  src: url('../../assets/fonts/barlow-condensed/BarlowCondensed-Bold.eot');
  src: url('../../assets/fonts/barlow-condensed/BarlowCondensed-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('../../assets/fonts/barlow-condensed/BarlowCondensed-Bold.ttf')
      format('truetype'),
    url('../../assets/fonts/barlow-condensed/BarlowCondensed-Bold.woff')
      format('woff'),
    url('../../assets/fonts/barlow-condensed/BarlowCondensed-Bold.woff2')
      format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Barlow Condensed Regular';
  src: url('../../assets/fonts/barlow-condensed/BarlowCondensed-Regular.eot');
  src: url('../../assets/fonts/barlow-condensed/BarlowCondensed-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../../assets/fonts/barlow-condensed/BarlowCondensed-Regular.ttf')
      format('truetype'),
    url('../../assets/fonts/barlow-condensed/BarlowCondensed-Regular.woff')
      format('woff'),
    url('../../assets/fonts/barlow-condensed/BarlowCondensed-Regular.woff2')
      format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}
