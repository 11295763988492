@use 'sass:color';
@import '../../../shared';

$base-font-serif: 'Open Sans', sans-serif !default;
$base-font-sans-serif: 'Open Sans', sans-serif !default;

$base-color-primary-main: #002244 !default;
$base-color-secondary-main: #061830 !default;
$base-color-accent-main: #bc44bc !default;
$base-color-accent-secondary: #a32bcf !default;

:root {
  /**
   * Color scheme
   */
  --color-scheme-primary-main: #{$base-color-primary-main};
  --color-scheme-secondary-main: #{$base-color-secondary-main};
  --color-scheme-accent-main: #{$base-color-accent-main};
  --color-scheme-accent-secondary: #{$base-color-accent-secondary};

  /**
   * Classic Header -> src/components/headers/ClassicHeader/index.tsx
   */
  --classic-header-height: 60px;
  --classic-header-large-height: 60px;
  --classic-header-logo-height: 20px;

  @include mq($from: desktop) {
    --classic-header-height: 75px;
    --classic-header-large-height: 90px;
    --classic-header-logo-height: 30px;
  }

  --classic-header-heading-font-family: #{$base-font-serif};
  --classic-header-heading-font-weight: 700;
  --classic-header-text-font-family: #{$base-font-sans-serif};
  --classic-header-color: #333;
  --classic-header-background-color: #fff;
  --classic-header-breadcrumbs-color: #667;

  /**
   * Hub Nav -> src/components/hub/shared/Nav/index.tsx
   */
  --hub-nav-height: 60px;
  --hub-nav-font-family: #{$base-font-sans-serif};
  --hub-nav-font-weight: 700;
  --hub-nav-color: #fff;
  --hub-nav-active-border-color: #fff;
  --hub-nav-background-color: #{$base-color-primary-main};
  --hub-nav-dropdown-background-color: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.2)
    ),
    #{$base-color-primary-main};
  --hub-nav-browse-menu-color: #fff;

  /**
   * Hub Mobile Nav -> src/components/hub/shared/MobileNav/index.tsx
   */
  --hub-mobile-nav-height: 60px;
  --hub-mobile-nav-sticky-height: 60px;
  --hub-mobile-nav-logo-height: 40px;
  --hub-mobile-nav-background-color: #{$base-color-primary-main};

  /**
   * Classic Footer -> src/components/footers/ClassicFooter/index.tsx
   */
  --classic-footer-text-font-family: #{$base-font-serif};
  --classic-footer-color: #fff;
  --classic-footer-background-color: #525a5b;
  --classic-footer-logo-max-height: 60px;
  --classic-footer-copyright-font-size: 14px;
  --classic-footer-copyright-color: #fff;

  @include mq($from: wide) {
    --classic-footer-copyright-font-size: 16px;
  }

  /**
   * Hub button
   */
  --hub-button-font-family: #{$base-font-serif};
  --hub-button-text-top-offset: 0;

  --hub-button-contained-color: #fff;
  --hub-button-contained-color-hover: #fff;
  --hub-button-contained-background: #{$base-color-accent-main};
  --hub-button-contained-background-hover: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.15),
      rgba(0, 0, 0, 0.15)
    ),
    #{$base-color-accent-secondary};

  --hub-button-outlined-color: #{$base-color-primary-main};
  --hub-button-outlined-color-hover: #{$base-color-primary-main};
  --hub-button-outlined-background: #fff;
  --hub-button-outlined-background-hover: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.15),
      rgba(0, 0, 0, 0.15)
    ),
    #ffffff;
  --hub-button-outlined-border: #fff;
  --hub-button-outlined-border-hover: #fff;

  --hub-button-small-min-height: 34px;
  --hub-button-small-padding: 0 25px;
  --hub-button-small-font-size: 14px;
  --hub-button-small-font-weight: 400;
  --hub-button-small-border-radius: 34px;

  --hub-button-medium-min-height: 40px;
  --hub-button-medium-padding: 0 30px;
  --hub-button-medium-font-size: 16px;
  --hub-button-medium-font-weight: 400;
  --hub-button-medium-border-radius: 40px;

  --hub-button-large-min-height: 48px;
  --hub-button-large-padding: 0 30px;
  --hub-button-large-font-size: 18px;
  --hub-button-large-font-weight: 400;
  --hub-button-large-border-radius: 48px;

  /**
   * Hub link
   */
  --hub-link-color: #{$base-color-accent-main};
  --hub-link-color-hover: #{$base-color-accent-secondary};

  /**
   * Hub article page
   */
  --hub-article-home-heading-font-family: #{$base-font-serif};
  --hub-article-home-heading-color: #{$base-color-primary-main};
  --hub-article-home-text-font-family: #{$base-font-sans-serif};

  /**
   * Hub authentication
   */
  --hub-authentication-heading-font-family: #{$base-font-serif};
  --hub-authentication-heading-color: #{$base-color-primary-main};
  --hub-authentication-input-font-family: #{$base-font-sans-serif};
  --hub-authentication-error-color: #c9474d;
  --hub-authentication-success-color: #31b03f;
  --hub-authentication-success-color-hover: #268830;

  /**
   * Hub industry home page
   */
  --hub-industry-home-hero-background-color: #{$base-color-primary-main};
  --hub-industry-home-hero-quote-font-family: #{$base-font-serif};
  --hub-industry-home-hero-quote-color: #fff;
  --hub-industry-home-hero-quote-border-color: #979797;

  --hub-industry-home-module-font-family: #{$base-font-serif};
  --hub-industry-home-module-color: #{$base-color-primary-main};

  --hub-industry-home-topic-card-heading-font-family: #{$base-font-serif};
  --hub-industry-home-topic-card-text-color: #{$base-color-primary-main};
  --hub-industry-home-topic-card-background-color: #e5e5e5;
  --hub-industry-home-topic-card-description-color: #000;
  --hub-industry-home-topic-card-brands-title-color: #6a6f71;

  /**
   * Hub Search proposition
   */
  --hub-search-proposition-title-font-family: #{$base-font-serif};
  --hub-search-proposition-background-color: #f5f5f5;
  --hub-search-proposition-subtitle-color: #333;

  /**
   * Hub Module
   */
  --hub-module-heading-font-family: #{$base-font-serif};
  --hub-module-text-font-family: #{$base-font-sans-serif};

  --hub-module-dark-color: #fff;
  --hub-module-dark-background-color: #{$base-color-primary-main};
  --hub-module-darker-background-color: #{lighten($base-color-primary-main, 5%)};
  --hub-module-dark-line-color: rgba(255, 252, 252, 1);

  --hub-module-light-color: #{$base-color-primary-main};
  --hub-module-light-background-color: #fff;
  --hub-module-light-line-color: rgba(0, 0, 0, 0.2);

  --hub-module-padding: 35px;
  @include mq($from: desktop) {
    --hub-module-padding: 65px;
  }
  @include mq($from: wide) {
    --hub-module-padding: 85px;
  }

  /**
   * Hub Hero Module
   */
  --hub-hero-module-pre-title-font-family: #{$base-font-serif};
  --hub-hero-module-pre-title-font-size: 18px;

  --hub-hero-module-value-proposition-font-family: #{$base-font-serif};
  --hub-hero-module-value-proposition-font-size: 24px;
  --hub-hero-module-value-proposition-smaller-font-size: 24px;
  --hub-hero-module-value-proposition-font-weight: 400;
  --hub-hero-module-value-proposition-line-height: 120%;

  --hub-hero-module-keyword-search-title-font-family: #{$base-font-serif};
  --hub-hero-module-keyword-search-title-font-size: 16px;
  --hub-hero-module-keyword-search-max-width: 530px;

  --hub-hero-module-overlay-background: linear-gradient(
    90deg,
    var(--color-scheme-primary-main) 0%,
    transparent 100%
  );

  @include mq($from: desktop) {
    --hub-hero-module-pre-title-font-size: 28px;
    --hub-hero-module-value-proposition-font-size: 32px;
    --hub-hero-module-value-proposition-smaller-font-size: 32px;
    --hub-hero-module-keyword-search-title-font-size: 24px;
  }

  @include mq($from: wide) {
    --hub-hero-module-value-proposition-font-size: 48px;
    --hub-hero-module-value-proposition-smaller-font-size: 40px;
  }

  /**
   * Hub Product Module
   */
  --hub-product-module-heading-color: #{$base-color-primary-main};
  --hub-product-module-color: #{$base-color-primary-main};
  --hub-product-module-color-hover: #fff;
  --hub-product-module-accent-color: #{$base-color-accent-main};
  --hub-product-module-secondary-color: #6a6f71;
  --hub-product-module-border-color: #d9d9d9;
  --hub-product-module-card-title-font-family: #{$base-font-serif};
  --hub-product-module-card-background-color: #{$base-color-primary-main};
  --hub-product-module-top-filter-dropdown-font-family: #{$base-font-serif};
  --hub-product-module-top-filter-dropdown-label-font-family: #{$base-font-sans-serif};

  /**
   * Hub Accordion Module
   */
  --hub-accordion-module-dark-item-line-color: rgba(255, 252, 252, 0.4);
  --hub-accordion-module-light-item-line-color: rgba(41, 51, 104, 0.4);

  /**
   * Hub Column Text Module
   */
  --hub-column-text-module-color: #{$base-color-primary-main};
  --hub-column-text-module-item-background-color: #fff;
  --hub-column-text-module-item-border-color: #{$base-color-primary-main};
  --hub-column-text-module-dark-item-cta-border-color: #{$base-color-accent-main};
  --hub-column-text-module-light-item-cta-border-color: #{$base-color-accent-main};
  --hub-column-text-module-brand-dark-color: #fff;
  --hub-column-text-module-brand-dark-background-color: #{$base-color-primary-main};
  --hub-column-text-module-brand-light-color: #fff;
  --hub-column-text-module-brand-light-background-color: #{$base-color-accent-main};
  --hub-column-text-module-icon-width: 57px;
  --hub-column-text-module-icon-height: 57px;

  /**
   * Hub Carousel Module
   */
  --hub-carousel-module-background-color: #{$base-color-primary-main};
  --hub-carousel-module-slide-background-color: #{$base-color-secondary-main};

  /**
   * Hub Email Opt In Module
   */
  --hub-email-opt-in-module-error-color: rgba(255, 0, 0, 1);
  --hub-email-opt-in-module-input-color: rgba(255, 255, 255, 0.33);

  /**
   * Hub Testimonials Module
   */
  --hub-testimonials-module-color: #000;
  --hub-testimonials-module-border-color: rgba(0, 0, 0, 0.2);

  /**
   * Hub Sectors module
   */
  --hub-sectors-module-heading-font-size: 24px;
  --hub-sectors-module-heading-font-weight: 400;
  --hub-sectors-module-heading-line-height: 40px;
  --hub-sectors-module-heading-color: #{$base-color-primary-main};

  --hub-sectors-module-text-font-size: 18px;
  --hub-sectors-module-text-font-weight: 300;
  --hub-sectors-module-text-line-height: 24px;
  --hub-sectors-module-text-color: #{$base-color-primary-main};

  --hub-sectors-module-topic-font-family: #{$base-font-sans-serif};
  --hub-sectors-module-topic-name-font-family: #{$base-font-serif};
  --hub-sectors-module-topic-color: #{$base-color-primary-main};

  @include mq($from: desktop) {
    --hub-sectors-module-heading-font-size: 32px;
    --hub-sectors-module-heading-line-height: 48px;
    --hub-sectors-module-text-font-size: 22px;
    --hub-sectors-module-text-line-height: 32px;
  }

  /**
   * Hub Rich Text Module
   */
  --hub-rich-text-module-dark-color: #fff;
  --hub-rich-text-module-light-color: #{$base-color-primary-main};

  /**
   * Jigsaw
   */
  --jigsaw-radius: 40px;
  --jigsaw-width: calc((100vw - 1480px) / 2); // 1480px = 1440px + 20px * 2
  --jigsaw-height: 150px;

  /**
   * Ripple
   */
  --ripple-size: 50px;
  --ripple-color: #{$base-color-primary-main};
}

.hub-module-light-theme + .hub-module-light-theme {
  .hub-module-wrapper {
    padding-top: 0;
  }
}
