$base-font-serif: 'Aleo', serif !default;
$base-font-sans-serif: 'Open Sans', sans-serif !default;

$base-color-primary-main: #002244 !default;
$base-color-secondary-main: #061830 !default;
$base-color-accent-main: #bc44bc !default;
$base-color-accent-secondary: #a32bcf !default;

@import '../main/theme';
@import '../../../fonts/barlow-condensed';
@import '../../../fonts/barlow';

:root {
  /**
   * Hub Nav -> src/components/hub/shared/Nav/index.tsx
   */
  --hub-nav-height: 65px;

  /**
   * Hub Mobile Nav -> src/components/hub/shared/MobileNav/index.tsx
   */
  --hub-mobile-nav-height: 100px;

  /**
   * Classic Footer -> src/components/footers/ClassicFooter/index.tsx
   */
  --classic-footer-background-color: #333;

  /**
   * Hub button
   */
  --hub-button-text-top-offset: 1px;

  /**
   * Hub Home Hero Banner (Brand Hub only)
   */
  .brand-hub .hub-home-hero-module {
    --hub-hero-module-pre-title-font-size: 22px;

    --hub-hero-module-value-proposition-font-size: 32px;
    --hub-hero-module-value-proposition-font-weight: 300;
    --hub-hero-module-value-proposition-line-height: 48px;

    --hub-hero-module-keyword-search-max-width: auto;
    --hub-hero-module-keyword-search-title-font-size: 18px;

    --hub-hero-module-overlay-background: none;

    @include mq($from: desktop) {
      --hub-hero-module-pre-title-font-size: 32px;
      --hub-hero-module-value-proposition-font-size: 38px;
      --hub-hero-module-keyword-search-title-font-size: 22px;
    }

    @include mq($from: wide) {
      --hub-hero-module-value-proposition-font-size: 48px;
      --hub-hero-module-value-proposition-line-height: 64px;
    }
  }
}

.brand-hub {
  .hub-home-hero-module {
    padding: 170px 0 140px;

    @include mq($until: desktop) {
      background-position: 78% top;
      background-size: auto 63%;
    }

    @include mq($from: desktop) {
      padding: 140px 0 205px;
    }
  }

  .hub-hero-module-overlay {
    &::after {
      @include mq($until: desktop) {
        background: rgba($base-color-primary-main, 30%)
          linear-gradient(
            180deg,
            rgba(0, 34, 68, 0) 19.27%,
            #{$base-color-primary-main} 64.06%
          );
      }
    }
  }
}
