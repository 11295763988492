@font-face {
  font-family: 'Barlow Regular';
  src: url('../../assets/fonts/barlow/Barlow-Regular.eot');
  src: url('../../assets/fonts/barlow/Barlow-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../../assets/fonts/barlow/Barlow-Regular.otf') format('otf'),
    url('../../assets/fonts/barlow/Barlow-Regular.ttf') format('truetype'),
    url('../../assets/fonts/barlow/Barlow-Regular.woff') format('woff'),
    url('../../assets/fonts/barlow/Barlow-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}
